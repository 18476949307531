import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Contacts = (props) => (
  <Layout>
    <SEO
      customTitle="Мои Контакты"
      customDescription="Свяжитесь со мной по email, либо пишите в vk!"
      canonical={props.location.pathname}
    />
    <Inner>
      <Container>
        <h1>Как со мной связаться?</h1>
        <p>
          Напишите мне в{' '}
          <a
            style={{ color: '#0099cc' }}
            href="https://vk.com/id595845309"
            target="_blank"
            rel="noopener noreferrer"
          >
            вк
          </a>
          , либо на email{' '}
          <a
            style={{ borderBottom: '2px solid #FF0000' }}
            href="mailto:vmouravi@yandex.ru"
          >
            vmouravi@yandex.ru
          </a>
        </p>
      </Container>
    </Inner>
  </Layout>
);

const Inner = styled.div`
  max-width: 600px;
  margin: 50px auto 0;
  padding: ${(props) => props.theme.innerPadding};
  min-height: 70vh;
`;

const Container = styled.div``;

export default Contacts;
